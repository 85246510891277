import { Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import locale from 'date-fns/locale/en-GB';
import "react-datepicker/dist/react-datepicker.css";
import { momentFormate } from "../../helpers/generalHelper";
import moment from "moment";

//Import components 
import InputGroupDropdown from "./jobForm/InputGroupDropdown";
import ScheduleGroupDropdown from "./schedule/ScheduleGroupDropdown";

//Import helpers
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";

const ScheduleModalAppointment = ({
  appointmentRecordForModal,
  action,
  visible,
  onClose,
  setLoadingModalVisible,
  setConfirmationModalVisible,
  returnAndLoadSchedule
}) => {
  const appointmentTypeOptions = [
    { value: 'Personal leave', label: 'Personal leave' },
    { value: 'Holiday', label: 'Holiday' },
    { value: 'Unavailable', label: 'Unavailable' },
    { value: 'Job hold', label: 'Job hold' }
  ];

  // Local state for dates and form values
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [userID, setuserID] = useState(null);
  const [userIDOptions, setuserIDOptions] = useState([{ value: '', label: 'Select' }]);
  const [inspectorObjArray, setInspectorObjArray] = useState([]);
  const [officeUserID, setOfficeUserID] = useState(null);

  // Manage Formik initial values
  const [formInitialValues, setFormInitialValues] = useState({
    appointmentID: '',
    officeUserID: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo')).displayName
      : "",
    userID: '',
    appointmentType: 'Personal leave',
    appointmentStartDate: momentFormate(startDate), // Expected as DD-MM-YYYY
    appointmentEndDate: momentFormate(endDate),     // Expected as DD-MM-YYYY
    appointmentStartTime: '7:00 AM',
    appointmentEndTime: '6:00 PM',
    appointmentComments: ''
  });

  const submitbtn = useRef(null);

  useEffect(() => {
    getCurrentUserAttributes();
  }, []);

  const getCurrentUserAttributes = async () => {
    const currentUserAttributes = await postRequest("/getCurrentUserAttributes", {});
    console.log(currentUserAttributes);
    setOfficeUserID(currentUserAttributes.name);
  };

  const deleteAppointment = async (appointmentID) => {
    setLoadingModalVisible(true);
    const response = await postRequestReturnAll("/deletePrivateAppointment", { appointmentID });
    setLoadingModalVisible(false);
    if ([200, 201, 202].includes(response.status)) {
      setConfirmationModalVisible(true);
      setTimeout(() => {
        setConfirmationModalVisible(false);
        returnAndLoadSchedule();
      }, 2000);
    } else {
      console.log('An error has occurred when deleting an appointment');
    }
  };

  // Update form values and date picker states when editing an existing appointment
  useEffect(() => {
    if (appointmentRecordForModal && Object.keys(appointmentRecordForModal).length !== 0) {
      // Convert the stored "DD-MM-YYYY" string to a Date object.
      const sd = new Date(appointmentRecordForModal.appointmentStartDate.split('-').reverse().join("-"));
      const ed = new Date(appointmentRecordForModal.appointmentEndDate.split('-').reverse().join("-"));
      setStartDate(sd);
      setEndDate(ed);

      setFormInitialValues({
        appointmentID: appointmentRecordForModal.appointmentID,
        officeUserID: appointmentRecordForModal.officeUserID,
        appointmentType: appointmentRecordForModal.appointmentType,
        userID: appointmentRecordForModal.userID,
        appointmentStartDate: momentFormate(sd),
        appointmentEndDate: momentFormate(ed),
        appointmentStartTime: appointmentRecordForModal.appointmentStartTime,
        appointmentEndTime: appointmentRecordForModal.appointmentEndTime,
        appointmentComments: appointmentRecordForModal.appointmentComments
      });
    } else {
      // Reset to default values if no appointment record is provided
      setFormInitialValues({
        appointmentID: '',
        officeUserID: localStorage.getItem('userInfo')
          ? JSON.parse(localStorage.getItem('userInfo')).displayName
          : "",
        userID: '',
        appointmentType: 'Personal leave',
        appointmentStartDate: momentFormate(startDate),
        appointmentEndDate: momentFormate(endDate),
        appointmentStartTime: '7:00 AM',
        appointmentEndTime: '6:00 PM',
        appointmentComments: ''
      });
    }
  }, [appointmentRecordForModal, startDate, endDate]);

  useEffect(() => {
    const getInspectorUserObjArray = async () => {
      const inspectorData = await postRequest("/getInspectorUserObjArray");
      setInspectorObjArray(inspectorData);
      let tempInspectorObjArray = [{ value: '', label: 'Select' }];
      inspectorData.forEach((inspectorObj) => {
        tempInspectorObjArray.push({ value: inspectorObj.uid, label: inspectorObj.displayName });
      });
      setuserIDOptions(tempInspectorObjArray);
    };

    getInspectorUserObjArray();
  }, []);

  // Updated getDateVal function using moment to ensure the DD-MM-YYYY format is used
  const getDateVal = (dateString, timeString) => {
    // Determine the proper time format
    const timeFormat = timeString.includes("AM") || timeString.includes("PM") ? "h:mm A" : "HH:mm";
    return moment(`${dateString} ${timeString}`, `DD-MM-YYYY ${timeFormat}`).valueOf();
  };

  const submitForm = async (values) => {
    setLoadingModalVisible(true);
    console.log('Form values:>>>>>>>>>> ', values);
    let formValues = { ...values };

    if (formValues.userID === '') {
      alert('Please select an inspector');
      setLoadingModalVisible(false);
      return;
    }

    const appointmentStartDateVal = getDateVal(values.appointmentStartDate, values.appointmentStartTime);
    const appointmentEndDateVal = getDateVal(values.appointmentEndDate, values.appointmentEndTime);

    formValues.appointmentStartDateVal = appointmentStartDateVal;
    formValues.appointmentEndDateVal = appointmentEndDateVal;
    formValues.officeUserID = officeUserID;

    let response;
    if (formValues.appointmentID === '') {
      response = await postRequestReturnAll("/createPrivateAppointment", formValues);
    } else {
      response = await postRequestReturnAll("/updatePrivateAppointment", formValues);
    }

    setLoadingModalVisible(false);
    if ([200, 201, 202].includes(response.status)) {
      setConfirmationModalVisible(true);
      setTimeout(() => {
        setConfirmationModalVisible(false);
        returnAndLoadSchedule();
      }, 2000);
    } else {
      console.log('An error has occurred when submitting an appointment to the server');
    }
  };

  // Separate component for the appointment form
  const AppointmentForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        onSubmit={(values, { resetForm }) => {
          submitForm(values);
          resetForm();
        }}>
        {({ touched, errors, values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
          <Form name='appointment' id="appointment" onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label" htmlFor="appointmentID">
                    Appointment ID<span className="text-danger">*</span> - Populated after appointment creation
                  </label>
                  <input
                    className="form-control"
                    disabled={true}
                    type="text"
                    id="appointmentID"
                    name="appointmentID"
                    value={values.appointmentID}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="form-label" htmlFor="officeUserID">
                    Office UserID<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    disabled={true}
                    type="text"
                    id="officeUserID"
                    name="officeUserID"
                    value={values.officeUserID}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <ScheduleGroupDropdown
                    inputName={"userID"}
                    inputLabelName={"Inspector"}
                    dropdownOptions={userIDOptions}
                    dropdownHandler={setuserID}
                    setFieldValue={setFieldValue}
                    value={values.userID}
                    required={true}
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <ScheduleGroupDropdown
                    inputName={"appointmentType"}
                    inputLabelName={"Appointment type"}
                    dropdownOptions={appointmentTypeOptions}
                    setFieldValue={setFieldValue}
                    value={values.appointmentType}
                    required={true}
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label" htmlFor="appointmentStartDate">
                    Appointment start date<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    name="appointmentStartDate"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    locale={locale}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setFieldValue("appointmentStartDate", momentFormate(date));
                      // Auto-update the end date if the new start date is after the current end date
                      if (date > endDate) {
                        setEndDate(date);
                        setFieldValue("appointmentEndDate", momentFormate(date));
                      }
                    }}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label className="form-label" htmlFor="appointmentEndDate">
                    Appointment end date<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    name="appointmentEndDate"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    locale={locale}
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setFieldValue("appointmentEndDate", momentFormate(date));
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label" htmlFor="appointmentStartTime">
                    Start Time<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="appointmentStartTime"
                    value={values.appointmentStartTime}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label className="form-label" htmlFor="appointmentEndTime">
                    End Time<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="appointmentEndTime"
                    value={values.appointmentEndTime}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
                
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label" htmlFor="appointmentComments">Appointment comments</label>
                  <textarea
                    className="form-control focus:outline-none"
                    rows={4}
                    id="appointmentComments"
                    name="appointmentComments"
                    value={values.appointmentComments}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {/* Hidden submit button for programmatic submission */}
              <button className="d-none" type="submit" ref={submitbtn}>submit</button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Modal
      show={visible}
      onHide={onClose}
      size="xl"
      backdrop="static"
      keyboard={false}>
      <Modal.Header>
        <Modal.Title>Scheduler - Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AppointmentForm />
      </Modal.Body>
      <Modal.Footer>
        {appointmentRecordForModal !== null && (
          <Button
            type='button'
            variant="danger"
            onClick={() => {
              deleteAppointment(appointmentRecordForModal.appointmentID);
              onClose();
            }}>
            Delete
          </Button>
        )}
        <Button
          type='button'
          variant="success"
          onClick={() => {
            // Trigger form submission via the hidden button
            submitbtn.current.click();
            onClose();
          }}>
          {appointmentRecordForModal !== null ? <span>Update</span> : <span>Create</span>}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ScheduleModalAppointment;
